.logo
{
    height: 17vh;
    width: 11vw;
    padding: 30px;
}

.company-name 
{
    display: inline;
    font-size: 25px;
    margin-top: 5px;
}
.form-check-inline {
    display: inline-block;
    margin-right: 5rem !important;
}
.row-margin
{
   margin: 12px;
   padding-top: 18px;
}
.form-in-line
{
    padding-left: 20px;
    margin-bottom: 0;
}
.column-display
{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
}
.column-center-display
{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    padding-top: 5px;
}
.button-center-display
{
    display: flex;
    justify-content: center;
    align-content: center;
}
.button-display
{
    height: 45px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom-padding
{
    padding-bottom:20px;
}
.submit-display {
    height: 45px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (min-width:300px) and (max-width:800px) {
    .logo
    {
        height:10px;
        width:20px;
        padding: 30px;
    }
    .company-name {
        display: inline;
        font-size: 25px;
        margin-top: 5px;
    }
    .column-center-display {
        padding-top: 5px;
    display: flex;
    justify-content: flex-start;
    }
    .bottom-padding
{
    padding-bottom:20px;
}
.button-center-display {
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.form-check-inline {
    display: inline-block;
    margin-right: 1rem !important;
    padding-top: 20px;
}
  }